import React from "react"
import "./ExamReview.less"
import { useTranslation } from "react-i18next"
import { Checkbox } from "antd"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"

const Question = ({ quiz, userAnswers }) => {
  const { t } = useTranslation()

  const { question, answers } = quiz

  const _userAnswers = quiz.answers.filter(answer =>
    userAnswers.includes(answer.id)
  )

  const allAnswersAreCorrect = _userAnswers.reduce((accumulator, current) => {
    return accumulator && current.is_correct
  }, true)

  const getAnswerClass = answer => {
    const classes = []

    if (answer.is_correct) {
      classes.push("correct")
    } else if (userAnswers.includes(answer.id)) {
      classes.push("wrong")
    }

    return classes.join(" ")
  }

  return (
    <div className="question">
      <h2>
        {allAnswersAreCorrect ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" />
        )}{" "}
        {t("label:question")}:{" "}
      </h2>
      <hr />
      <p className="question-text">{question}</p>
      <ul className="answer-wrapper">
        {answers.map(answer => (
          <li key={answer.id} className={getAnswerClass(answer)}>
            <Checkbox
              checked={userAnswers.includes(answer.id) || answer.is_correct}
            >
              {answer.content}
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  )
}

const ExamReview = ({ quizzes, userAnswers }) => {
  const { t } = useTranslation()
  return (
    <div className="exam-wrapper">
      <h1>{t("label:examReview")}</h1>
      <br />
      {quizzes.map(quiz => (
        <Question quiz={quiz} userAnswers={userAnswers} />
      ))}
    </div>
  )
}

export default ExamReview
